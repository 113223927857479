.main-container {
	width: 100%;
	height: 100%;
}

.announcement {
	margin: 3rem auto;
	width: 90%;
	max-width: 700px;
	opacity: 0.8;
	h6 {
		line-height: 1.5;
		.account-link {
			text-decoration: underline;
			cursor: pointer;
			&:hover {
				opacity: 0.8;
			}
		}
	}
}
