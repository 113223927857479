.side-menu {
	width: 100vh;
	position: fixed;
	top: calc(50% - 30px);
	height: 60px;
	display: flex;
	flex-direction: row-reverse;
	&.mobile {
		display: none;
	}
	&.left {
		left: calc(-50vh + 30px);
		background-color: #000;
		transform: rotate(-90deg);
		padding-left: 20px;
		&.main {
			border-bottom: 1px solid #fff;
		}
	}
	&.right {
		right: calc(-50vh + 30px);
		transform: rotate(-90deg);
		background-color: #ff0060;
		align-items: center;
		padding: 0px 0px 0px 20px;
		.menu-text {
			color: #000;
			overflow: hidden;
			width: 100%;
			white-space: nowrap;
			line-height: 1;
		}
		&.auth {
			background-color: #ffbf00;
		}
	}
	.top {
		display: flex;
		width: 50%;
		justify-content: space-between;
		flex-direction: row-reverse;
		align-items: center;
		.logo-container {
			position: relative;
			height: 60px;
			width: 80px;
			border-left: 1px solid #fff;
			img {
				position: absolute;
				left: 19px;
				top: 4.25px;
				transform: rotate(90deg);
				width: 43px;
				height: 53px;
			}
		}
	}
	.bottom {
		width: 50%;
		display: flex;
		align-items: center;
	}
	.menu-text {
		font-family: Platelet, sans-serif;
		font-size: 24px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 0.58;
		letter-spacing: normal;
		text-transform: uppercase;
		color: #ffffff;
		&.yellow {
			color: #ffbf00;
		}
	}
}

@media screen and (max-width: 767px) {
	.side-menu {
		&.mobile {
			display: flex;
		}
		&.desktop {
			display: none;
		}
		width: 100vw;
		position: fixed;
		top: 0;
		height: 75px;
		display: flex;
		flex-direction: row;
		z-index: 5;
		left: 0;
		background-color: #000;
		transform: none;
		padding: 0 20px;
		border-bottom: 1px solid #fff;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		.logo-container {
			width: 32px;
			height: 50px;
			img {
				width: 100%;
				height: 100%;
			}
		}
	}
	.mobile-menu {
		width: 100%;
		height: 100%;
		background-color: #000;
		.menu-content {
			height: 100%;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			.top,
			.bottom {
				h1 {
					font-family: Aura, sans-serif;
					font-size: 80px;
					font-weight: normal;
					font-stretch: normal;
					font-style: normal;
					letter-spacing: -6px;
					color: #ffbf00;
					padding: 10px 20px 0;
					line-height: 0.5;
					text-transform: uppercase;
					border-bottom: 1px solid #ffbf00;
				}
				text-align: right;
				padding: 2rem 0;
				display: flex;
				flex-direction: column;
			}
		}
		.menu-text {
			font-family: Platelet, sans-serif;
			font-size: 24px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: 0.58;
			letter-spacing: normal;
			text-transform: uppercase;
			padding: 1rem 2rem;
			color: #ffffff;
		}
	}
	.bm-burger-button {
		position: fixed;
		z-index: 999;
		width: 36px;
		height: 30px;
		right: 20px;
		top: 22.5px;
	}
	.bm-burger-bars {
		background: #fff;
	}

	/* Position and sizing of clickable cross button */
	.bm-cross-button {
		height: 24px;
		width: 24px;
		right: auto !important;
		left: 8px !important;
		width: 30px !important;
		height: 30px !important;
		top: 24px !important;
	}

	/* Color/shape of close button cross */
	.bm-cross {
		background: #ff0060;
	}
}
