@import url("https://fonts.googleapis.com/css?family=Work+Sans&display=swap");

.dashboard {
  height: 100%;
  display: flex;
  font-family: "Work Sans", sans-serif;
  font-size: 12px;
  > div {
    width: 100%;
  }
}

.upper {
  text-transform: uppercase;
}

.pointer {
  cursor: pointer;
}

.grey {
  color: #000;
  opacity: 0.4;
}

.menu-left {
  height: 100vh;
  display: flex;
  align-items: center;
  width: 237px;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  left: 0;
  .menu-1 {
    background: #fff;
    width: 60px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    .top {
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      padding: 10px 8.5px;
      padding-bottom: 20px;
      .logo-container {
        width: 43px;
        height: 53px;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .bottom {
      height: 120px;
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      cursor: pointer;
      padding: 12.5px;
      p {
        text-align: center;
        cursor: pointer;
      }
    }
  }
}
.menu-2 {
  box-shadow: 1px 0 0 0 rgba(0, 0, 0, 0.1);
  background-color: #f2f4f7;
  height: 100%;
  width: 177px;
  .menu-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    .top {
      margin-top: 20px;
    }
    .bottom {
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;
    }
    .menu-item {
      margin-right: 30px;
      margin-left: 10px;
      margin: 10px 20px 10px 0px;
      padding: 0px 10px 0px 20px;
      border-left: 3px solid #f2f4f7;
      &.active {
        color: #5189e7;
        border-left: 3px solid #5189e7;
        opacity: 1;
      }
      &.yt-partner-link {
        color: red;
        opacity: 1;
        &.active {
          border-left: 3px solid red;
        }
      }
      &:hover {
        opacity: 0.8;
      }
    }
  }
}
.section-right {
  width: calc(100% - 237px);
  margin-left: auto;
}

.form-input {
  box-shadow: 0 1px 0 0 #ffffff;
  background-color: #f2f4f7;
  margin: 0;
  padding: 15px 20px !important;
  border: 0.5px solid #fff;
  &.first {
    border-top-left-radius: 30px;
  }
  &.second {
    border-top-right-radius: 30px;
  }

  &.second-last {
    border-bottom-left-radius: 30px;
  }

  label {
    text-transform: uppercase;
    color: #000;
    opacity: 0.4;
  }
  input,
  select,
  textarea {
    background: transparent !important;
    padding: 0;
    border: none;
    &:focus {
      outline: none;
      background: transparent;
      box-shadow: none;
    }
  }
  textarea {
    resize: none;
  }
}

table {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  thead {
    background-color: #f2f4f7;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    th {
      padding: 25px 20px;
      text-transform: uppercase;
      color: #000;
      opacity: 0.4;
      font-weight: 400;
    }
  }
  tr {
    td {
      padding: 20px;
    }
  }
}

@media screen and (max-width: 768px) {
  .dashboard {
    flex-direction: column;
    .menu-left {
      width: 100vw;
      height: 75px;
      background: #f2f4f7;
      z-index: 4;
      border: none;
      .menu-1 {
        border: none;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        flex-direction: row-reverse;
        align-items: center;
        width: 100%;
        background: #f2f4f7;
        .top {
          .logo-container {
            width: 34px;
          }
          border: none;
        }
        .bottom {
          height: auto;
          padding: 17.5px;
          .menu-icon-container {
            width: 40px;
            height: 40px;
            img {
              width: 100%;
              height: 100%;
              opacity: 0.4;
            }
          }
        }
      }
    }

    .menu-2 {
      width: 100%;
      .menu-item {
        font-size: 14px;
      }
    }
    .section-right {
      width: 100%;
      overflow-x: scroll;
      padding-top: 75px;
    }
  }
}
