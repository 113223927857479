@font-face {
	font-family: "Platelet";
	src: url("../fonts/Platelet_Regular.woff") format("woff");
}
@font-face {
	font-family: "Aura";
	src: url("../fonts/Aura-Regular.woff") format("woff");
}
@import url("https://fonts.googleapis.com/css?family=PT+Sans&display=swap");

.auth-container {
	padding: 0 60px;
	color: #fff;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	&.single-form {
		height: 100vh;
		align-items: center;
	}
	.bg-image {
		width: 100vw;
		height: 100vh;
		position: fixed;
		top: 0px;
		left: 0px;
		background-size: cover;
		z-index: -1;
		&.login {
			background-image: url("../images/bg-image-1.jpg");
		}
		&.register {
			background-image: url("../images/bg-image-2.jpg");
		}
	}
	.form-group-custom {
		box-shadow: 0 1px 0 0 #ffffff;
		background-color: #000000;
		width: 95%;
		max-width: 400px;
		margin: 0;
		border-bottom: 1px solid #fff;
		.form-check {
			label {
				padding: 10px;
				padding-left: 19px;
			}
			input {
				margin: 0px;
				margin-top: 12.5px;
			}
		}
		label,
		.forgot-pw-link,
		.errors {
			font-family: Platelet, sans-serif;
			font-size: 14px;
			text-transform: uppercase;
			color: #ffffff;
			padding: 16px 19px 0 19px;
		}
		.forgot-pw-link,
		.errors {
			margin-bottom: 0.5rem;
			padding: 16px 19px 0 0;
		}
		.errors {
			color: #ff0060;
			text-align: right;
		}
		input {
			background: transparent;
			font-family: PT Sans, sans-serif;
			font-size: 14px;
			color: #ffffff;
			border: none;
			border-radius: 0px;
			outline: none;
			padding-left: 19px;
			&:focus {
				box-shadow: none;
			}
		}
	}
	.form-submit-container {
		width: 95%;
		max-width: 400px;
		height: 80px;
		button {
			width: 100%;
			height: 100%;
			background-color: #ffbf00;
			color: #000;
			outline: none;
			border-radius: 0px;
			&:focus {
				background-color: #ffbf00 !important;
				color: #000 !important;
				outline: none !important;
			}
			&:active {
				background-color: #ffbf00 !important;
				color: #000 !important;
				outline: none;
			}
		}
	}
	.halves {
		display: flex;
		&.first {
			margin-top: 50px;
		}
		.left-half {
			width: 50%;
			padding-left: 2px;
			h2 {
				padding-left: 20px;
				margin-bottom: 35px;
			}
			&.links {
				display: flex;
				flex-direction: column;
				justify-content: flex-end;
				align-items: flex-start;
				padding: 20px;
				font-size: 14px;
				font-family: Platelet, sans-serif;
				text-transform: uppercase;
			}
		}
		.right-half {
			width: 50%;
			display: flex;
			flex-direction: column;
			h2 {
				padding-left: 20px;
				margin-bottom: 35px;
			}
			.oauth-btn {
				width: 95%;
				max-width: 373px;
				height: 80px;
				color: #131415;
				border: none;
				outline: none;
				font-family: Platelet, sans-serif;
				font-size: 24px;
				margin-bottom: 2px;
				&.fb-login {
					background-color: #5189e7;
				}
				&.google-login {
					background-color: #ff0060;
				}
			}
			&.sign-up-container {
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				font-size: 14px;
				font-family: Platelet, sans-serif;
				text-transform: uppercase;
				.register-login-link {
					height: 100px;
					width: 95%;
					max-width: 373px;
					padding: 18px;
					margin-top: 12px;
					border: solid 1px #ffbf00;
					font-family: Platelet, sans-serif;
					font-size: 24px;
					color: #ffbf00;
				}
			}
		}
	}
}
.auth-form-h1 {
	font-family: Aura, sans-serif;
	font-size: 200px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	letter-spacing: -15px;
	color: #ffffff;
	padding: 20px;
	padding-top: 80px;
	line-height: 0.5;
	text-transform: uppercase;
}
.auth-form-heading {
	font-family: Platelet, sans-serif;
	font-size: 72px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 0.61;
	letter-spacing: -7px;
	color: #ffffff;
	text-transform: uppercase;
}

.relative-btn {
	position: relative;
	padding: 0;
	border: none;
	.text {
		font-family: Platelet, sans-serif;
		font-size: 24px;
		line-height: 24px;
		text-transform: uppercase;
		position: absolute;
		top: 18px;
		left: 19px;
		white-space: nowrap;
	}
	.arrow {
		position: absolute;
		height: 20px;
		bottom: 20px;
		right: 20px;
	}
}

.forgot-pw-link {
	font-size: 12px;
	opacity: 0.8;
	cursor: pointer;
}
.label-container {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.form-check {
	position: relative;
	.check {
		padding-top: 12px !important;
		padding-left: 40px !important;
		padding-bottom: 4px !important;
		cursor: pointer;
	}
}

/* Customize the label (the container) */
.container {
	display: block;
	position: relative;
	padding-left: 35px;
	margin-bottom: 12px;
	cursor: pointer;
	font-size: 22px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

/* Hide the browser's default checkbox */
.check input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
}

/* Create a custom checkbox */
.checkmark {
	position: absolute;
	top: 10px;
	left: 19px;
	height: 25px;
	width: 25px;
	background-color: #000;
	border: 1px solid #ffbf00;
}

/* On mouse-over, add a grey background color */
.check:hover input ~ .checkmark {
	background-color: #111;
}

/* When the checkbox is checked, add a blue background */
.check input:checked ~ .checkmark {
	background-color: #ffbf00;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
	content: "";
	position: absolute;
	display: none;
}

/* Show the checkmark when checked */
.check input:checked ~ .checkmark:after {
	display: block;
}

/* Style the checkmark/indicator */
.check .checkmark:after {
	left: 9px;
	top: 5px;
	width: 5px;
	height: 10px;
	border: solid #000;
	border-width: 0 3px 3px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
}

input.is-invalid {
	border: 1px solid #ff0060 !important;
}

.checkmark.is-invalid {
	border: 1px solid #ff0060 !important;
}

.modal-dialog,
.modal-content {
	background: #000;
	border-radius: 0px;
	border: 1px solid #ffbf00;
	color: #fff;
	.modal-header {
		border-bottom: none;
	}
	button.close {
		color: #ffbf00;
		text-shadow: none;
		opacity: 1;
	}
	input {
		background: transparent;
		font-family: PT Sans;
		font-size: 14px;
		color: #ffffff;
		border: none;
		border-radius: 0px;
		outline: none;
		padding-left: 19px;
		&:focus {
			box-shadow: none;
			background: transparent;
			font-family: PT Sans, sans-serif;
			color: #fff;
		}
	}
	label,
	.errors {
		font-family: Platelet, sans-serif;
		font-size: 14px;
		text-transform: uppercase;
		color: #ffffff;
		padding: 16px 19px 0 19px;
	}
	.errors {
		margin-bottom: 0.5rem;
		padding: 16px 19px 0 0;
		color: #ff0060;
		text-align: right;
	}
	.modal-footer {
		padding: 0;
	}
	.form-submit-container {
		width: 100%;
		height: 80px;
		button {
			width: 100%;
			height: 100%;
			background-color: #ffbf00;
			color: #000;
			outline: none;
			border-radius: 0px;
			&:focus {
				background-color: #ffbf00;
				color: #000;
				outline: none;
			}
			&:active {
				background-color: #ffbf00;
				color: #000;
				outline: none;
			}
		}
	}
}

.toc-form {
	margin: auto;
}

@media screen and (max-width: 990px) {
	.auth-container {
		.auth-form-h1 {
			text-align: center;
		}
		.halves {
			&.first {
				flex-direction: column;
				margin: 0;
				.left-half,
				.right-half {
					width: 100%;
					h2 {
						padding: 0px;
						text-align: center;
					}
				}
				.left-half,
				.form-group-custom,
				.form-submit-container {
					margin: auto;
				}
				.right-half {
					margin-top: 1.75rem;
					.oauth-btn {
						margin: auto;
					}
					.form-container-centered {
						display: flex;
						justify-content: center;
					}
				}
			}
			&.second {
				margin-top: 2.5rem;
			}
		}
	}
}
@media screen and (max-width: 767px) {
	.auth-form-h1 {
		font-size: 80px;
		letter-spacing: -6px;
	}
	.auth-form-heading {
		font-size: 48px;
	}
	.auth-container {
		padding: 60px 0 0 0;
		.auth-form-h1 {
			text-align: center;
		}
		.halves {
			&.second {
				flex-direction: column;
				margin: 0;
				.left-half,
				.right-half {
					width: 100%;
				}
				.right-half {
					&.sign-up-container {
						span {
							padding: 10px 20px;
						}
						.register-login-link {
							margin: auto;
						}
					}
				}
			}
		}
	}
}
