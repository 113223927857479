.account-page {
	padding: 40px 80px 0 40px;
	.account-header.social {
		margin-top: 25px;
	}
	.account-header {
		margin-bottom: 1.5rem;
	}
	.account-reset-btn {
		margin-top: 25px;
		background-color: #5189e7;
		border-radius: 30px;
		padding: 10px 30px;
	}
	.btns {
		button {
			margin-right: 1rem;
		}
	}
}

@media screen and (max-width: 768px) {
	.account-page {
		padding: 10% 40px 10% 40px;
		.form-row {
			flex-direction: column;
			.col {
				width: 100%;
				&.first {
					border-top-right-radius: 30px;
				}
				&.second {
					border-radius: 0px;
				}
				&.second-last {
					border-radius: 0px;
				}
				&.last {
					border-bottom-left-radius: 30px;
				}
			}
		}
	}
}
