@import url(https://fonts.googleapis.com/css?family=PT+Sans&display=swap);
@import url(https://fonts.googleapis.com/css?family=PT+Sans&display=swap);
@import url(https://fonts.googleapis.com/css?family=Work+Sans&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;800&display=swap);
@import url(https://fonts.googleapis.com/css?family=Work+Sans&display=swap);
@import url(https://fonts.googleapis.com/css?family=Work+Sans&display=swap);
body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
		"Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
		"Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

a {
  color: inherit;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
  color: inherit;
}
@font-face {
  font-family: "Platelet";
  src: url(/static/media/Platelet_Regular.b03610ac.woff) format("woff"); }

@font-face {
  font-family: "Aura";
  src: url(/static/media/Aura-Regular.c8e57d7e.woff) format("woff"); }

.auth-container {
  padding: 0 60px;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between; }
  .auth-container.single-form {
    height: 100vh;
    align-items: center; }
  .auth-container .bg-image {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0px;
    left: 0px;
    background-size: cover;
    z-index: -1; }
    .auth-container .bg-image.login {
      background-image: url(/static/media/bg-image-1.e2c66817.jpg); }
    .auth-container .bg-image.register {
      background-image: url(/static/media/bg-image-2.4efc5f2c.jpg); }
  .auth-container .form-group-custom {
    box-shadow: 0 1px 0 0 #ffffff;
    background-color: #000000;
    width: 95%;
    max-width: 400px;
    margin: 0;
    border-bottom: 1px solid #fff; }
    .auth-container .form-group-custom .form-check label {
      padding: 10px;
      padding-left: 19px; }
    .auth-container .form-group-custom .form-check input {
      margin: 0px;
      margin-top: 12.5px; }
    .auth-container .form-group-custom label,
    .auth-container .form-group-custom .forgot-pw-link,
    .auth-container .form-group-custom .errors {
      font-family: Platelet, sans-serif;
      font-size: 14px;
      text-transform: uppercase;
      color: #ffffff;
      padding: 16px 19px 0 19px; }
    .auth-container .form-group-custom .forgot-pw-link,
    .auth-container .form-group-custom .errors {
      margin-bottom: 0.5rem;
      padding: 16px 19px 0 0; }
    .auth-container .form-group-custom .errors {
      color: #ff0060;
      text-align: right; }
    .auth-container .form-group-custom input {
      background: transparent;
      font-family: PT Sans, sans-serif;
      font-size: 14px;
      color: #ffffff;
      border: none;
      border-radius: 0px;
      outline: none;
      padding-left: 19px; }
      .auth-container .form-group-custom input:focus {
        box-shadow: none; }
  .auth-container .form-submit-container {
    width: 95%;
    max-width: 400px;
    height: 80px; }
    .auth-container .form-submit-container button {
      width: 100%;
      height: 100%;
      background-color: #ffbf00;
      color: #000;
      outline: none;
      border-radius: 0px; }
      .auth-container .form-submit-container button:focus {
        background-color: #ffbf00 !important;
        color: #000 !important;
        outline: none !important; }
      .auth-container .form-submit-container button:active {
        background-color: #ffbf00 !important;
        color: #000 !important;
        outline: none; }
  .auth-container .halves {
    display: flex; }
    .auth-container .halves.first {
      margin-top: 50px; }
    .auth-container .halves .left-half {
      width: 50%;
      padding-left: 2px; }
      .auth-container .halves .left-half h2 {
        padding-left: 20px;
        margin-bottom: 35px; }
      .auth-container .halves .left-half.links {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;
        padding: 20px;
        font-size: 14px;
        font-family: Platelet, sans-serif;
        text-transform: uppercase; }
    .auth-container .halves .right-half {
      width: 50%;
      display: flex;
      flex-direction: column; }
      .auth-container .halves .right-half h2 {
        padding-left: 20px;
        margin-bottom: 35px; }
      .auth-container .halves .right-half .oauth-btn {
        width: 95%;
        max-width: 373px;
        height: 80px;
        color: #131415;
        border: none;
        outline: none;
        font-family: Platelet, sans-serif;
        font-size: 24px;
        margin-bottom: 2px; }
        .auth-container .halves .right-half .oauth-btn.fb-login {
          background-color: #5189e7; }
        .auth-container .halves .right-half .oauth-btn.google-login {
          background-color: #ff0060; }
      .auth-container .halves .right-half.sign-up-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        font-size: 14px;
        font-family: Platelet, sans-serif;
        text-transform: uppercase; }
        .auth-container .halves .right-half.sign-up-container .register-login-link {
          height: 100px;
          width: 95%;
          max-width: 373px;
          padding: 18px;
          margin-top: 12px;
          border: solid 1px #ffbf00;
          font-family: Platelet, sans-serif;
          font-size: 24px;
          color: #ffbf00; }

.auth-form-h1 {
  font-family: Aura, sans-serif;
  font-size: 200px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -15px;
  color: #ffffff;
  padding: 20px;
  padding-top: 80px;
  line-height: 0.5;
  text-transform: uppercase; }

.auth-form-heading {
  font-family: Platelet, sans-serif;
  font-size: 72px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.61;
  letter-spacing: -7px;
  color: #ffffff;
  text-transform: uppercase; }

.relative-btn {
  position: relative;
  padding: 0;
  border: none; }
  .relative-btn .text {
    font-family: Platelet, sans-serif;
    font-size: 24px;
    line-height: 24px;
    text-transform: uppercase;
    position: absolute;
    top: 18px;
    left: 19px;
    white-space: nowrap; }
  .relative-btn .arrow {
    position: absolute;
    height: 20px;
    bottom: 20px;
    right: 20px; }

.forgot-pw-link {
  font-size: 12px;
  opacity: 0.8;
  cursor: pointer; }

.label-container {
  display: flex;
  justify-content: space-between;
  align-items: center; }

.form-check {
  position: relative; }
  .form-check .check {
    padding-top: 12px !important;
    padding-left: 40px !important;
    padding-bottom: 4px !important;
    cursor: pointer; }

/* Customize the label (the container) */
.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none; }

/* Hide the browser's default checkbox */
.check input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0; }

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 10px;
  left: 19px;
  height: 25px;
  width: 25px;
  background-color: #000;
  border: 1px solid #ffbf00; }

/* On mouse-over, add a grey background color */
.check:hover input ~ .checkmark {
  background-color: #111; }

/* When the checkbox is checked, add a blue background */
.check input:checked ~ .checkmark {
  background-color: #ffbf00; }

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none; }

/* Show the checkmark when checked */
.check input:checked ~ .checkmark:after {
  display: block; }

/* Style the checkmark/indicator */
.check .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid #000;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg); }

input.is-invalid {
  border: 1px solid #ff0060 !important; }

.checkmark.is-invalid {
  border: 1px solid #ff0060 !important; }

.modal-dialog,
.modal-content {
  background: #000;
  border-radius: 0px;
  border: 1px solid #ffbf00;
  color: #fff; }
  .modal-dialog .modal-header,
  .modal-content .modal-header {
    border-bottom: none; }
  .modal-dialog button.close,
  .modal-content button.close {
    color: #ffbf00;
    text-shadow: none;
    opacity: 1; }
  .modal-dialog input,
  .modal-content input {
    background: transparent;
    font-family: PT Sans;
    font-size: 14px;
    color: #ffffff;
    border: none;
    border-radius: 0px;
    outline: none;
    padding-left: 19px; }
    .modal-dialog input:focus,
    .modal-content input:focus {
      box-shadow: none;
      background: transparent;
      font-family: PT Sans, sans-serif;
      color: #fff; }
  .modal-dialog label,
  .modal-dialog .errors,
  .modal-content label,
  .modal-content .errors {
    font-family: Platelet, sans-serif;
    font-size: 14px;
    text-transform: uppercase;
    color: #ffffff;
    padding: 16px 19px 0 19px; }
  .modal-dialog .errors,
  .modal-content .errors {
    margin-bottom: 0.5rem;
    padding: 16px 19px 0 0;
    color: #ff0060;
    text-align: right; }
  .modal-dialog .modal-footer,
  .modal-content .modal-footer {
    padding: 0; }
  .modal-dialog .form-submit-container,
  .modal-content .form-submit-container {
    width: 100%;
    height: 80px; }
    .modal-dialog .form-submit-container button,
    .modal-content .form-submit-container button {
      width: 100%;
      height: 100%;
      background-color: #ffbf00;
      color: #000;
      outline: none;
      border-radius: 0px; }
      .modal-dialog .form-submit-container button:focus,
      .modal-content .form-submit-container button:focus {
        background-color: #ffbf00;
        color: #000;
        outline: none; }
      .modal-dialog .form-submit-container button:active,
      .modal-content .form-submit-container button:active {
        background-color: #ffbf00;
        color: #000;
        outline: none; }

.toc-form {
  margin: auto; }

@media screen and (max-width: 990px) {
  .auth-container .auth-form-h1 {
    text-align: center; }
  .auth-container .halves.first {
    flex-direction: column;
    margin: 0; }
    .auth-container .halves.first .left-half,
    .auth-container .halves.first .right-half {
      width: 100%; }
      .auth-container .halves.first .left-half h2,
      .auth-container .halves.first .right-half h2 {
        padding: 0px;
        text-align: center; }
    .auth-container .halves.first .left-half,
    .auth-container .halves.first .form-group-custom,
    .auth-container .halves.first .form-submit-container {
      margin: auto; }
    .auth-container .halves.first .right-half {
      margin-top: 1.75rem; }
      .auth-container .halves.first .right-half .oauth-btn {
        margin: auto; }
      .auth-container .halves.first .right-half .form-container-centered {
        display: flex;
        justify-content: center; }
  .auth-container .halves.second {
    margin-top: 2.5rem; } }

@media screen and (max-width: 767px) {
  .auth-form-h1 {
    font-size: 80px;
    letter-spacing: -6px; }
  .auth-form-heading {
    font-size: 48px; }
  .auth-container {
    padding: 60px 0 0 0; }
    .auth-container .auth-form-h1 {
      text-align: center; }
    .auth-container .halves.second {
      flex-direction: column;
      margin: 0; }
      .auth-container .halves.second .left-half,
      .auth-container .halves.second .right-half {
        width: 100%; }
      .auth-container .halves.second .right-half.sign-up-container span {
        padding: 10px 20px; }
      .auth-container .halves.second .right-half.sign-up-container .register-login-link {
        margin: auto; } }

.partner-register-container {
  background: #eee;
  padding: 1rem 0 5rem;
  min-height: 100vh;
  position: relative; }
  .partner-register-container .card {
    width: 95%;
    max-width: 600px;
    margin: auto;
    padding: 2rem; }
  .partner-register-container .check input:checked ~ .checkmark {
    background-color: #fff; }
  .partner-register-container .check input ~ .checkmark {
    background-color: #fff;
    border-color: #000; }
  .partner-register-container .check:hover input ~ .checkmark {
    background-color: #fff; }
  .partner-register-container .oauth-btn {
    color: #fff;
    text-transform: uppercase;
    padding: 1rem;
    width: 100%;
    max-width: 400px; }
    .partner-register-container .oauth-btn.fb-login {
      background-color: #3b5998;
      border-color: #3b5998;
      margin-bottom: 0.75rem; }
    .partner-register-container .oauth-btn.google-login {
      background-color: #c4302b;
      border-color: #c4302b; }
  .partner-register-container .navbar {
    position: absolute;
    bottom: 0;
    width: 100%; }
    .partner-register-container .navbar .navbar-nav:last-child {
      margin-left: auto;
      margin-right: 5rem; }
  .partner-register-container .form-container-centered {
    display: flex;
    flex-direction: column;
    align-items: center; }
  .partner-register-container h1,
  .partner-register-container h2 {
    text-align: center;
    margin-bottom: 2rem; }
  .partner-register-container h2 {
    margin-top: 2rem; }
  .partner-register-container .errors {
    color: #dc3545;
    font-size: 11px;
    margin-top: 0.25rem;
    margin-left: 0.25rem; }
  .partner-register-container .logo-cont {
    margin: auto;
    position: absolute;
    top: 22px; }
    .partner-register-container .logo-cont img {
      width: 100%; }
    .partner-register-container .logo-cont.hoodville {
      width: 52px;
      height: 52px;
      right: 30px;
      top: 25px; }
      .partner-register-container .logo-cont.hoodville img {
        border-radius: 50%; }
    .partner-register-container .logo-cont.pets-on-q {
      width: 80px;
      height: auto;
      right: 30px;
      top: 0px; }

@media screen and (max-width: 767px) {
  .partner-register-container .navbar {
    position: static;
    margin-top: 1rem; } }

@font-face {
  font-family: "Platelet";
  src: url(/static/media/Platelet_Regular.b03610ac.woff) format("woff"); }

@font-face {
  font-family: "Aura";
  src: url(/static/media/Aura-Regular.c8e57d7e.woff) format("woff"); }

.main-page-container {
  color: #fff;
  display: flex; }
  .main-page-container .bg-image {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0px;
    left: 0px;
    background-size: cover;
    z-index: -1;
    background-size: cover;
    background-image: url(/static/media/homepage-main.6dc20b8f.jpg); }
  .main-page-container .half {
    width: 50%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between; }
    .main-page-container .half.left {
      padding-left: 80px; }
      .main-page-container .half.left .main-page-title {
        width: 100%;
        max-width: 600px;
        position: relative; }
        .main-page-container .half.left .main-page-title .header-img {
          width: 95%;
          margin-top: 1rem; }
        .main-page-container .half.left .main-page-title h1 {
          padding-top: 60px;
          font-family: Aura, sans-serif;
          font-size: 200px;
          line-height: 0.5;
          letter-spacing: -9px;
          color: #ffffff;
          margin: 0;
          text-transform: uppercase; }
          .main-page-container .half.left .main-page-title h1 span {
            position: relative;
            left: 120px; }
        .main-page-container .half.left .main-page-title .header {
          display: flex;
          align-items: center; }
          .main-page-container .half.left .main-page-title .header h1 {
            width: 250px; }
        .main-page-container .half.left .main-page-title .up-right-arrow {
          position: absolute;
          right: 40px;
          top: 420px; }
        .main-page-container .half.left .main-page-title .x-icon {
          position: absolute;
          left: 10px;
          top: 215px; }
        .main-page-container .half.left .main-page-title .sign-up-starburst {
          position: absolute;
          top: 225px;
          left: 375px; }
          .main-page-container .half.left .main-page-title .sign-up-starburst .text {
            position: absolute;
            top: 42.5%;
            left: calc(50% - 55px);
            font-size: 22px;
            font-family: Platelet, sans-serif;
            text-transform: uppercase;
            color: #131415; }
          .main-page-container .half.left .main-page-title .sign-up-starburst .arrow {
            position: absolute;
            top: 30%;
            left: calc(50% - 10px); }
    .main-page-container .half.right {
      padding-right: 60px; }
      .main-page-container .half.right .page-desc {
        padding: 20px; }
        .main-page-container .half.right .page-desc h2 {
          width: 460px;
          font-family: Platelet, sans-serif;
          font-size: 72px;
          line-height: 0.61;
          letter-spacing: -7px;
          text-transform: uppercase; }
        .main-page-container .half.right .page-desc p {
          width: 460px;
          font-family: PT Sans, sans-serif;
          font-size: 14px;
          line-height: 1.71;
          letter-spacing: normal; }
      .main-page-container .half.right .signup-button {
        width: 271px;
        height: 100px;
        background-color: #ffbf00;
        color: #000; }
    .main-page-container .half .bottom-part {
      display: flex;
      justify-content: space-between;
      padding: 20px;
      font-size: 14px;
      font-family: Platelet, sans-serif;
      text-transform: uppercase;
      align-items: center; }
      .main-page-container .half .bottom-part .links {
        display: flex;
        flex-direction: column;
        justify-content: flex-end; }
      .main-page-container .half .bottom-part .learn-more {
        visibility: hidden;
        font-size: 24px;
        color: #ff0060; }

.flex {
  display: flex; }

.relative-btn {
  position: relative;
  padding: 0;
  border: none; }
  .relative-btn .text {
    font-family: Platelet, sans-serif;
    font-size: 24px;
    line-height: 24px;
    text-transform: uppercase;
    position: absolute;
    top: 18px;
    left: 19px;
    white-space: nowrap; }
  .relative-btn .arrow {
    position: absolute;
    height: 11px;
    bottom: 20px;
    right: 20px; }

@media screen and (max-width: 1320px) {
  .main-page-container .half.right .page-desc {
    text-align: right; }
    .main-page-container .half.right .page-desc h2,
    .main-page-container .half.right .page-desc p {
      margin-left: auto; }
  .main-page-container .half.right .signup-button {
    margin-left: auto;
    margin-right: 20px; } }

@media screen and (max-width: 1150px) {
  .main-page-container .half.left .main-page-title h1 {
    font-size: 150px;
    letter-spacing: -6px; }
    .main-page-container .half.left .main-page-title h1 span {
      left: 90px; }
  .main-page-container .half.left .main-page-title .x-icon {
    top: 175px;
    width: 70px;
    height: 70px; }
    .main-page-container .half.left .main-page-title .x-icon img {
      width: 100%;
      height: 100%; }
  .main-page-container .half.left .main-page-title .sign-up-starburst {
    width: 120px;
    height: 120px;
    left: 275px;
    top: 190px; }
    .main-page-container .half.left .main-page-title .sign-up-starburst .starburst {
      width: 100%;
      height: 100%; }
    .main-page-container .half.left .main-page-title .sign-up-starburst .text {
      font-size: 16px;
      left: calc(50% - 41px); }
  .main-page-container .half.left .main-page-title .up-right-arrow {
    width: 70px;
    height: 70px;
    top: 330px;
    right: 180px; }
    .main-page-container .half.left .main-page-title .up-right-arrow img {
      width: 100%;
      height: 100%; }
  .main-page-container .half.right .page-desc {
    text-align: right; }
    .main-page-container .half.right .page-desc h2,
    .main-page-container .half.right .page-desc p {
      margin-left: auto; }
  .main-page-container .half.right .signup-button {
    margin-left: auto;
    margin-right: 20px; } }

@media screen and (max-width: 990px) {
  .main-page-container {
    flex-direction: column; }
    .main-page-container .left.half .bottom-part {
      display: none; }
    .main-page-container .half {
      width: 100%;
      height: auto; } }

@media screen and (max-width: 767px) {
  .main-page-container .half.left {
    padding-left: 5%; }
    .main-page-container .half.left .main-page-title {
      padding-top: 75px;
      width: 100%;
      max-width: 600px; }
      .main-page-container .half.left .main-page-title h1 {
        font-size: 120px;
        letter-spacing: -4px; }
        .main-page-container .half.left .main-page-title h1 span {
          left: 60px; }
      .main-page-container .half.left .main-page-title .x-icon {
        top: 230px;
        width: 50px;
        height: 50px; }
        .main-page-container .half.left .main-page-title .x-icon img {
          width: 100%;
          height: 100%; }
      .main-page-container .half.left .main-page-title .sign-up-starburst {
        width: 120px;
        height: 120px;
        left: 210px;
        top: 225px; }
        .main-page-container .half.left .main-page-title .sign-up-starburst .starburst {
          width: 100%;
          height: 100%; }
        .main-page-container .half.left .main-page-title .sign-up-starburst .text {
          font-size: 14px;
          left: calc(50% - 35px); }
      .main-page-container .half.left .main-page-title .up-right-arrow {
        width: 60px;
        height: 60px;
        top: 350px;
        right: 20px; }
        .main-page-container .half.left .main-page-title .up-right-arrow img {
          width: 100%;
          height: 100%; }
  .main-page-container .half.right {
    padding: 0px; }
    .main-page-container .half.right .page-desc {
      text-align: right; }
      .main-page-container .half.right .page-desc h2,
      .main-page-container .half.right .page-desc p {
        width: 100%;
        margin: auto; }
        .main-page-container .half.right .page-desc h2 br,
        .main-page-container .half.right .page-desc p br {
          display: none; }
      .main-page-container .half.right .page-desc h2 {
        font-size: 48px;
        margin-bottom: 1.5rem; } }

.dashboard {
  height: 100%;
  display: flex;
  font-family: "Work Sans", sans-serif;
  font-size: 12px; }
  .dashboard > div {
    width: 100%; }

.upper {
  text-transform: uppercase; }

.pointer {
  cursor: pointer; }

.grey {
  color: #000;
  opacity: 0.4; }

.menu-left {
  height: 100vh;
  display: flex;
  align-items: center;
  width: 237px;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  left: 0; }
  .menu-left .menu-1 {
    background: #fff;
    width: 60px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-right: 1px solid rgba(0, 0, 0, 0.1); }
    .menu-left .menu-1 .top {
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      padding: 10px 8.5px;
      padding-bottom: 20px; }
      .menu-left .menu-1 .top .logo-container {
        width: 43px;
        height: 53px; }
        .menu-left .menu-1 .top .logo-container img {
          width: 100%;
          height: 100%; }
    .menu-left .menu-1 .bottom {
      height: 120px;
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      cursor: pointer;
      padding: 12.5px; }
      .menu-left .menu-1 .bottom p {
        text-align: center;
        cursor: pointer; }

.menu-2 {
  box-shadow: 1px 0 0 0 rgba(0, 0, 0, 0.1);
  background-color: #f2f4f7;
  height: 100%;
  width: 177px; }
  .menu-2 .menu-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%; }
    .menu-2 .menu-content .top {
      margin-top: 20px; }
    .menu-2 .menu-content .bottom {
      display: flex;
      flex-direction: column;
      margin-bottom: 20px; }
    .menu-2 .menu-content .menu-item {
      margin-right: 30px;
      margin-left: 10px;
      margin: 10px 20px 10px 0px;
      padding: 0px 10px 0px 20px;
      border-left: 3px solid #f2f4f7; }
      .menu-2 .menu-content .menu-item.active {
        color: #5189e7;
        border-left: 3px solid #5189e7;
        opacity: 1; }
      .menu-2 .menu-content .menu-item.yt-partner-link {
        color: red;
        opacity: 1; }
        .menu-2 .menu-content .menu-item.yt-partner-link.active {
          border-left: 3px solid red; }
      .menu-2 .menu-content .menu-item:hover {
        opacity: 0.8; }

.section-right {
  width: calc(100% - 237px);
  margin-left: auto; }

.form-input {
  box-shadow: 0 1px 0 0 #ffffff;
  background-color: #f2f4f7;
  margin: 0;
  padding: 15px 20px !important;
  border: 0.5px solid #fff; }
  .form-input.first {
    border-top-left-radius: 30px; }
  .form-input.second {
    border-top-right-radius: 30px; }
  .form-input.second-last {
    border-bottom-left-radius: 30px; }
  .form-input label {
    text-transform: uppercase;
    color: #000;
    opacity: 0.4; }
  .form-input input,
  .form-input select,
  .form-input textarea {
    background: transparent !important;
    padding: 0;
    border: none; }
    .form-input input:focus,
    .form-input select:focus,
    .form-input textarea:focus {
      outline: none;
      background: transparent;
      box-shadow: none; }
  .form-input textarea {
    resize: none; }

table {
  width: 100%;
  height: 100%;
  overflow-y: scroll; }
  table thead {
    background-color: #f2f4f7;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1); }
    table thead th {
      padding: 25px 20px;
      text-transform: uppercase;
      color: #000;
      opacity: 0.4;
      font-weight: 400; }
  table tr td {
    padding: 20px; }

@media screen and (max-width: 768px) {
  .dashboard {
    flex-direction: column; }
    .dashboard .menu-left {
      width: 100vw;
      height: 75px;
      background: #f2f4f7;
      z-index: 4;
      border: none; }
      .dashboard .menu-left .menu-1 {
        border: none;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        flex-direction: row-reverse;
        align-items: center;
        width: 100%;
        background: #f2f4f7; }
        .dashboard .menu-left .menu-1 .top {
          border: none; }
          .dashboard .menu-left .menu-1 .top .logo-container {
            width: 34px; }
        .dashboard .menu-left .menu-1 .bottom {
          height: auto;
          padding: 17.5px; }
          .dashboard .menu-left .menu-1 .bottom .menu-icon-container {
            width: 40px;
            height: 40px; }
            .dashboard .menu-left .menu-1 .bottom .menu-icon-container img {
              width: 100%;
              height: 100%;
              opacity: 0.4; }
    .dashboard .menu-2 {
      width: 100%; }
      .dashboard .menu-2 .menu-item {
        font-size: 14px; }
    .dashboard .section-right {
      width: 100%;
      overflow-x: scroll;
      padding-top: 75px; } }

.upload-nav-bar {
  display: flex;
  width: 100%;
  height: 60px;
  background: #f2f4f7;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1); }
  .upload-nav-bar .upload-item {
    padding: 20px;
    margin: 0 20px; }
    .upload-nav-bar .upload-item.active {
      color: #5189e7;
      border-bottom: 1px solid #5189e7; }

.upload-form-container {
  width: 100%;
  outline: none;
  padding: 60px 80px 60px 40px; }
  .upload-form-container .form-text {
    display: flex;
    justify-content: space-between; }
    .upload-form-container .form-text h3 {
      letter-spacing: -1px; }
    .upload-form-container .form-text p {
      font-size: 14px;
      margin-bottom: 1.5rem; }
  .upload-form-container .dropzone-container {
    width: 100%;
    border-radius: 30px;
    border-bottom-right-radius: 0px;
    background-color: #f2f4f7;
    padding: 20px;
    margin-bottom: 1.5rem; }
    .upload-form-container .dropzone-container .form-group {
      margin-bottom: 0px; }
    .upload-form-container .dropzone-container .dropzone .dropzone-input {
      height: 300px;
      border: dashed 1px #5189e7;
      border-radius: 30px;
      border-bottom-right-radius: 0px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column; }
      .upload-form-container .dropzone-container .dropzone .dropzone-input p {
        font-size: 24px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: -1px;
        color: #5189e7;
        padding: 0 7.5%;
        text-align: center; }
      .upload-form-container .dropzone-container .dropzone .dropzone-input.has-files {
        border: none;
        background-color: #e2e9f5;
        position: relative; }
        .upload-form-container .dropzone-container .dropzone .dropzone-input.has-files .clear-files-btn {
          color: #000;
          opacity: 0.4;
          background: transparent;
          border: 2px solid rgba(0, 0, 0, 0.4);
          border-radius: 50%;
          position: absolute;
          top: 20px;
          right: 20px;
          font-weight: 700;
          font-size: 14px;
          height: 18px;
          width: 18px;
          padding-top: 0px;
          padding-bottom: 15px;
          padding-left: 1px;
          padding-right: 1px;
          line-height: 14px; }
          .upload-form-container .dropzone-container .dropzone .dropzone-input.has-files .clear-files-btn:hover {
            opacity: 0.5;
            border: 2px solid rgba(0, 0, 0, 0.5); }
  .upload-form-container .upload-validation {
    display: flex;
    margin-left: 20px; }
    .upload-form-container .upload-validation .form-check {
      margin-right: 1.5rem;
      height: 25px;
      display: flex;
      align-items: center; }
      .upload-form-container .upload-validation .form-check input {
        width: 25px;
        height: 25px;
        margin-top: 0px; }
      .upload-form-container .upload-validation .form-check label {
        width: 365px;
        margin-left: 0.75rem; }
        .upload-form-container .upload-validation .form-check label a {
          text-decoration: underline; }
      .upload-form-container .upload-validation .form-check .invalid-feedback {
        display: none; }
  .upload-form-container .submit-btn {
    width: 100%;
    height: 80px;
    border-radius: 30px;
    border-top-right-radius: 0px;
    background-color: #5189e7;
    text-align: left;
    padding: 20px 60px 20px 20px;
    margin: 20px 0;
    font-size: 24px;
    line-height: 1.25;
    letter-spacing: -1px;
    display: flex;
    align-items: center;
    position: relative; }
    .upload-form-container .submit-btn.disabled {
      opacity: 0.3; }
    .upload-form-container .submit-btn.page-two {
      margin: 0;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }
    .upload-form-container .submit-btn span.loading {
      margin-left: 16px; }
    .upload-form-container .submit-btn svg {
      padding-bottom: 3px; }
    .upload-form-container .submit-btn .arrow-container {
      position: absolute;
      top: 0;
      right: 0;
      width: 80px;
      height: 80px;
      border-left: 1px solid rgba(255, 255, 255, 0.2); }
      .upload-form-container .submit-btn .arrow-container img {
        position: absolute;
        top: calc(50% - 5px);
        left: calc(50% - 9px); }
  .upload-form-container .page-two-header {
    margin-bottom: 1.5rem; }
  .upload-form-container .mobile-text {
    display: none; }
  .upload-form-container .file-name-finish {
    width: 50%; }
  .upload-form-container .phone-container {
    display: flex; }
  .upload-form-container .react-tel-input .form-control {
    border: none;
    width: 6rem;
    flex: 0 1 auto;
    font-size: 16px;
    height: calc(1.5em + 0.75rem + 2px); }
  .upload-form-container .progress {
    width: 50%;
    margin-left: auto;
    margin-top: 1rem; }
  .upload-form-container .page-three-container {
    text-align: center;
    max-width: 500px;
    margin: auto; }
    .upload-form-container .page-three-container h6 {
      margin: 10px 0 20px; }
    .upload-form-container .page-three-container .account-link {
      text-decoration: underline;
      cursor: pointer; }
      .upload-form-container .page-three-container .account-link:hover {
        opacity: 0.8; }
  .upload-form-container .form-control {
    font-size: 16px; }

.PhoneInputInput {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  background-clip: padding-box;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  font-size: 16px;
  margin-left: 0.5rem; }

.PhoneInputCountryIcon {
  margin: 0 0.25rem; }

@media screen and (max-width: 768px) {
  .upload-form-container {
    padding: 30px 20px; }
    .upload-form-container .form-text {
      flex-direction: column; }
      .upload-form-container .form-text p {
        font-size: 14px;
        margin-bottom: 0.5rem; }
      .upload-form-container .form-text div > p {
        margin-bottom: 1.5rem; }
    .upload-form-container .upload-validation {
      flex-direction: column; }
    .upload-form-container .mobile-text {
      display: block; }
    .upload-form-container .desktop-text {
      display: none; }
    .upload-form-container .form-page-two .form-row {
      flex-direction: column; }
      .upload-form-container .form-page-two .form-row .col {
        width: 100%; }
        .upload-form-container .form-page-two .form-row .col.first {
          border-top-right-radius: 30px; }
        .upload-form-container .form-page-two .form-row .col.second {
          border-radius: 0px; }
        .upload-form-container .form-page-two .form-row .col.second-last {
          border-radius: 0px; }
        .upload-form-container .form-page-two .form-row .col.last {
          border-bottom-left-radius: 30px; }
    .upload-form-container .file-name-finish {
      width: 100%; } }

@media screen and (max-width: 767px) {
  .upload-nav-bar {
    justify-content: center; }
    .upload-nav-bar .upload-item {
      display: flex;
      align-items: center;
      padding: 10px;
      margin: 0 7.5px; }
  .upload-form-container .submit-btn {
    font-size: 20px; }
    .upload-form-container .submit-btn .arrow-container {
      width: 60px; } }

.main-container {
  width: 100%;
  height: 100%; }
  .main-container table td video {
    border-radius: 10px; }

.approval-status {
  width: 272px;
  height: 30px;
  border-radius: 15px;
  display: flex;
  color: #fff;
  border-bottom-right-radius: 0px; }
  .approval-status .img-container {
    width: 35px;
    height: 30px;
    position: relative; }
    .approval-status .img-container img {
      position: absolute;
      top: 5px;
      left: 7.5px;
      width: 20px;
      height: 20px; }
      .approval-status .img-container img.check {
        top: 9px;
        left: 11.5px;
        width: 12px;
        height: 12px; }
  .approval-status .txt-container {
    border-left: 1px solid rgba(255, 255, 255, 0.6);
    padding: 6px 1rem; }
  .approval-status.approved {
    background-color: #3dcc55; }
  .approval-status.rejected {
    background-color: #ff0060; }
  .approval-status.pending {
    background-color: #ffbf00; }

.main-container {
  width: 100%;
  height: 100%; }

.announcement {
  margin: 3rem auto;
  width: 90%;
  max-width: 700px;
  opacity: 0.8; }
  .announcement h6 {
    line-height: 1.5; }
    .announcement h6 .account-link {
      text-decoration: underline;
      cursor: pointer; }
      .announcement h6 .account-link:hover {
        opacity: 0.8; }

.dashboard-page {
  padding: 40px 80px 0 40px;
  max-height: 100vh;
  overflow: scroll; }
  .dashboard-page .dashboard-header {
    margin-top: 10px; }
  .dashboard-page .dashboard-tooltip {
    width: 100%;
    padding: 8px 60px;
    margin-bottom: 15px;
    font-size: 16px;
    border-radius: 15px;
    display: flex;
    color: #fff;
    border-bottom-right-radius: 0px;
    background-color: #ffbf00;
    position: relative;
    cursor: pointer; }
    .dashboard-page .dashboard-tooltip .img-container {
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;
      border-left: 1px solid #fff;
      padding: 10px 20px;
      display: flex;
      align-items: center; }
      .dashboard-page .dashboard-tooltip .img-container img {
        width: 20px;
        height: 20px; }
    .dashboard-page .dashboard-tooltip .txt-container {
      border-left: 1px solid rgba(255, 255, 255, 0.6);
      padding: 6px 1rem; }
  .dashboard-page .videos-cont {
    background-color: #f2f4f7;
    padding: 20px;
    border-radius: 30px;
    margin-bottom: 30px; }
    .dashboard-page .videos-cont .header {
      text-transform: uppercase;
      color: #000;
      opacity: 0.4; }
  .dashboard-page .video-cont {
    position: relative;
    padding-bottom: 33%;
    height: 0;
    width: 60%; }
    .dashboard-page .video-cont iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; }
  .dashboard-page .description {
    width: 40%;
    padding-left: 40px; }
    .dashboard-page .description p {
      font-size: 16px; }
    .dashboard-page .description button {
      background-color: #5189e7;
      border-radius: 30px;
      padding: 10px 30px;
      margin: auto; }
  .dashboard-page .help-text {
    max-width: 600px;
    margin: 30px auto;
    font-size: 16px; }
  .dashboard-page .center {
    text-align: center; }
  .dashboard-page .youtube-text {
    color: red; }
  .dashboard-page .abc-image {
    max-width: 600px;
    width: 50%;
    margin: 0 auto 30px; }
  @media screen and (max-width: 1200px) {
    .dashboard-page .embed-cont {
      flex-direction: column; }
    .dashboard-page .video-cont {
      width: 100%;
      margin-bottom: 30px;
      padding-bottom: 56.25%; }
    .dashboard-page .description {
      width: 100%;
      padding-left: 0;
      display: flex;
      flex-direction: column; }
      .dashboard-page .description button {
        margin: auto; } }

@media screen and (max-width: 768px) {
  .dashboard-page {
    padding: 10% 40px 10% 40px; } }

.account-page {
  padding: 40px 80px 0 40px; }
  .account-page .account-header.social {
    margin-top: 25px; }
  .account-page .account-header {
    margin-bottom: 1.5rem; }
  .account-page .account-reset-btn {
    margin-top: 25px;
    background-color: #5189e7;
    border-radius: 30px;
    padding: 10px 30px; }
  .account-page .btns button {
    margin-right: 1rem; }

@media screen and (max-width: 768px) {
  .account-page {
    padding: 10% 40px 10% 40px; }
    .account-page .form-row {
      flex-direction: column; }
      .account-page .form-row .col {
        width: 100%; }
        .account-page .form-row .col.first {
          border-top-right-radius: 30px; }
        .account-page .form-row .col.second {
          border-radius: 0px; }
        .account-page .form-row .col.second-last {
          border-radius: 0px; }
        .account-page .form-row .col.last {
          border-bottom-left-radius: 30px; } }

.partner-container {
  padding: 40px 80px 0 40px; }
  .partner-container h3 {
    color: #5189e7;
    font-size: 42px;
    margin-bottom: 1rem; }
  .partner-container h6 {
    font-size: 24px;
    margin-bottom: 2rem; }
  .partner-container .submit-area {
    margin-top: 4rem; }
    .partner-container .submit-area p {
      font-weight: 700;
      font-size: 16px;
      padding-left: 1rem; }
  .partner-container.upload-form-container .upload-validation .form-check {
    height: auto; }
  @media screen and (max-width: 767px) {
    .partner-container {
      padding: 20px 5% 40px; }
      .partner-container .submit-area {
        margin-top: 1rem; }
        .partner-container .submit-area p {
          padding-left: 0; }
      .partner-container .label-area p {
        padding-left: 0; }
      .partner-container .channel-btns {
        flex-direction: column; }
        .partner-container .channel-btns .submit-btn {
          width: 100%;
          margin: 10px 0; }
        .partner-container .channel-btns .back-btn {
          margin: 10px 0; } }

.yt-submit {
  background-color: red !important;
  border-color: red !important; }

.channel-select .channel {
  margin-left: 1rem;
  border: 1px solid #ddd;
  display: inline-block; }
  .channel-select .channel img {
    width: 60px;
    height: 60px; }
  .channel-select .channel span {
    margin: 0 1rem;
    font-size: 16px;
    font-weight: bold; }

.label-area p {
  font-weight: 400;
  font-size: 16px;
  padding-left: 1rem; }

.channel-btns {
  display: flex;
  margin-top: 10px; }
  .channel-btns .submit-btn {
    width: 400px;
    margin: 10px 0; }
  .channel-btns .back-btn {
    margin: 10px;
    background-color: #aaa;
    border-color: #aaa; }

.partner-video {
  position: fixed;
  z-index: -99;
  width: 100%;
  height: 100%;
  background-color: #000; }
  .partner-video video,
  .partner-video img {
    width: 100%;
    height: 100%; }

@media screen and (max-width: 767px) {
  .partner-video video,
  .partner-video img {
    height: 100%;
    width: auto;
    margin-left: calc(-10.5 / 16 * 100vh); } }

.partner-details .logo-cont {
  margin: auto;
  width: 200px;
  position: absolute;
  left: calc(50% - 100px);
  top: 20%;
  box-shadow: 0px 0px 30px 11px rgba(0, 0, 0, 0.25);
  border-radius: 50%; }
  .partner-details .logo-cont img {
    width: 100%;
    border-radius: 50%; }

.partner-details .signup-btn {
  margin: auto;
  width: 330px;
  position: absolute;
  left: calc(50% - 165px);
  top: calc(40% + 200px);
  box-shadow: 0px 0px 30px 11px rgba(0, 0, 0, 0.25); }
  .partner-details .signup-btn button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    color: #fff;
    text-transform: uppercase;
    font-weight: 700;
    padding: 15px 20px;
    font-size: 24px;
    border-color: #000;
    outline: none !important; }
    .partner-details .signup-btn button svg {
      width: 30px;
      height: 30px;
      stroke: #fff;
      fill: #fff; }

#partnerBackgroundVideo {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  z-index: -1; }

.partner-page {
  padding: 1rem 0;
  width: 90%;
  max-width: 1400px;
  margin: auto;
  font-family: "Poppins", sans-serif; }
  .partner-page .logo-header {
    display: flex;
    justify-content: center; }
  .partner-page .desc-block {
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;
    margin-top: 1rem; }
    .partner-page .desc-block.no-video {
      justify-content: center; }
      .partner-page .desc-block.no-video .desc-cta {
        display: flex;
        flex-direction: column;
        align-items: center; }
        .partner-page .desc-block.no-video .desc-cta h2,
        .partner-page .desc-block.no-video .desc-cta p {
          text-align: center; }
    .partner-page .desc-block > div {
      width: 50%;
      padding: 1rem; }
    .partner-page .desc-block.reverse {
      flex-direction: row-reverse; }
    .partner-page .desc-block .desc-cta {
      padding-right: 2rem; }
      .partner-page .desc-block .desc-cta h2 {
        text-transform: uppercase;
        font-weight: 800;
        font-size: 55px;
        line-height: 1;
        margin-bottom: 1.5rem; }
        .partner-page .desc-block .desc-cta h2 .blue {
          color: #0089d1; }
      .partner-page .desc-block .desc-cta p.extra-margin {
        margin-bottom: 1.5rem; }
      .partner-page .desc-block .desc-cta button {
        background-color: #0089d1;
        text-transform: uppercase;
        font-weight: 800;
        padding: 20px 50px; }
    .partner-page .desc-block .image-cont video {
      width: 100%; }

@media screen and (max-width: 1000px) {
  .pets-on-q .desc-block {
    flex-direction: column; }
    .pets-on-q .desc-block > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%; }
      .pets-on-q .desc-block > div p {
        width: 100%;
        max-width: 500px; }
      .pets-on-q .desc-block > div h2 {
        max-width: 500px; }
    .pets-on-q .desc-block.reverse {
      display: flex;
      flex-direction: column-reverse; }
    .pets-on-q .desc-block .image-cont img {
      width: 100%; }
    .pets-on-q .desc-block .image-cont video {
      width: 100%; } }

.side-menu {
  width: 100vh;
  position: fixed;
  top: calc(50% - 30px);
  height: 60px;
  display: flex;
  flex-direction: row-reverse; }
  .side-menu.mobile {
    display: none; }
  .side-menu.left {
    left: calc(-50vh + 30px);
    background-color: #000;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
    padding-left: 20px; }
    .side-menu.left.main {
      border-bottom: 1px solid #fff; }
  .side-menu.right {
    right: calc(-50vh + 30px);
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
    background-color: #ff0060;
    align-items: center;
    padding: 0px 0px 0px 20px; }
    .side-menu.right .menu-text {
      color: #000;
      overflow: hidden;
      width: 100%;
      white-space: nowrap;
      line-height: 1; }
    .side-menu.right.auth {
      background-color: #ffbf00; }
  .side-menu .top {
    display: flex;
    width: 50%;
    justify-content: space-between;
    flex-direction: row-reverse;
    align-items: center; }
    .side-menu .top .logo-container {
      position: relative;
      height: 60px;
      width: 80px;
      border-left: 1px solid #fff; }
      .side-menu .top .logo-container img {
        position: absolute;
        left: 19px;
        top: 4.25px;
        -webkit-transform: rotate(90deg);
                transform: rotate(90deg);
        width: 43px;
        height: 53px; }
  .side-menu .bottom {
    width: 50%;
    display: flex;
    align-items: center; }
  .side-menu .menu-text {
    font-family: Platelet, sans-serif;
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.58;
    letter-spacing: normal;
    text-transform: uppercase;
    color: #ffffff; }
    .side-menu .menu-text.yellow {
      color: #ffbf00; }

@media screen and (max-width: 767px) {
  .side-menu {
    width: 100vw;
    position: fixed;
    top: 0;
    height: 75px;
    display: flex;
    flex-direction: row;
    z-index: 5;
    left: 0;
    background-color: #000;
    -webkit-transform: none;
            transform: none;
    padding: 0 20px;
    border-bottom: 1px solid #fff;
    flex-direction: row;
    justify-content: space-between;
    align-items: center; }
    .side-menu.mobile {
      display: flex; }
    .side-menu.desktop {
      display: none; }
    .side-menu .logo-container {
      width: 32px;
      height: 50px; }
      .side-menu .logo-container img {
        width: 100%;
        height: 100%; }
  .mobile-menu {
    width: 100%;
    height: 100%;
    background-color: #000; }
    .mobile-menu .menu-content {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between; }
      .mobile-menu .menu-content .top,
      .mobile-menu .menu-content .bottom {
        text-align: right;
        padding: 2rem 0;
        display: flex;
        flex-direction: column; }
        .mobile-menu .menu-content .top h1,
        .mobile-menu .menu-content .bottom h1 {
          font-family: Aura, sans-serif;
          font-size: 80px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: -6px;
          color: #ffbf00;
          padding: 10px 20px 0;
          line-height: 0.5;
          text-transform: uppercase;
          border-bottom: 1px solid #ffbf00; }
    .mobile-menu .menu-text {
      font-family: Platelet, sans-serif;
      font-size: 24px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 0.58;
      letter-spacing: normal;
      text-transform: uppercase;
      padding: 1rem 2rem;
      color: #ffffff; }
  .bm-burger-button {
    position: fixed;
    z-index: 999;
    width: 36px;
    height: 30px;
    right: 20px;
    top: 22.5px; }
  .bm-burger-bars {
    background: #fff; }
  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 24px;
    width: 24px;
    right: auto !important;
    left: 8px !important;
    width: 30px !important;
    height: 30px !important;
    top: 24px !important; }
  /* Color/shape of close button cross */
  .bm-cross {
    background: #ff0060; } }

.new-user-page-container {
  background-color: #fff;
  font-family: Work Sans; }

.new-user-page-section {
  min-height: calc(100vh - 5rem); }

@media screen and (max-width: 991px) {
  .new-user-page-section {
    min-height: calc(100vh - 20rem); } }

.new-user-page-section .new-user-page-register-btn {
  align-self: center;
  margin-bottom: 3rem; }

.new-user-page-section {
  display: flex;
  flex-direction: column;
  align-items: center; }

.new-user-page-section h1 {
  margin: 3rem; }

.new-user-page-section p {
  font-size: 1.75rem;
  text-align: center; }

.new-user-page-section-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 4rem; }

.submit-btn {
  width: 350px;
  height: 80px;
  border-radius: 30px;
  border-top-right-radius: 0px;
  background-color: #5189e7;
  text-align: left;
  padding: 20px 35px 20px 20px;
  margin: 20px 0;
  font-size: 24px;
  line-height: 1.25;
  letter-spacing: -1px;
  display: flex;
  align-items: center;
  position: relative; }
  .submit-btn.disabled {
    opacity: 0.3; }
  .submit-btn span.loading {
    margin-left: 16px; }
  .submit-btn svg {
    padding-bottom: 3px; }
  .submit-btn .arrow-container {
    position: absolute;
    top: 0;
    right: 0;
    width: 80px;
    height: 80px;
    border-left: 1px solid rgba(255, 255, 255, 0.2); }
    .submit-btn .arrow-container img {
      position: absolute;
      top: calc(50% - 5px);
      left: calc(50% - 9px); }

body {
  font-family: "Work Sans"; }

.policy-menu {
  position: fixed;
  z-index: 999;
  height: 75px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  width: 100%;
  background: #fff; }
  .policy-menu .back {
    border-right: 1px solid rgba(0, 0, 0, 0.2);
    opacity: 0.5;
    padding: 0 20px;
    display: flex;
    align-items: center;
    cursor: pointer;
    height: 100%; }
    .policy-menu .back img {
      -webkit-transform: rotate(180deg);
              transform: rotate(180deg);
      margin-right: 5px;
      vertical-align: middle;
      padding-top: 2px; }
  .policy-menu .logo-container {
    width: 40px;
    height: 60px; }
    .policy-menu .logo-container img {
      width: 100%;
      height: 100%; }

.policy-container {
  padding-top: 85px;
  width: 95%;
  max-width: 1200px;
  margin: auto; }
  .policy-container h1,
  .policy-container h2 {
    text-align: center;
    margin-top: 1rem;
    margin-bottom: 2rem; }
  .policy-container h2 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem; }
  .policy-container.contrib-agreement ol li {
    margin-bottom: 1rem; }
  .policy-container.contrib-agreement p {
    margin: 0 0 0.25rem; }
    .policy-container.contrib-agreement p.ml-8 {
      margin-left: 3rem; }

