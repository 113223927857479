.main-container {
	width: 100%;
	height: 100%;
	table {
		td {
			video {
				border-radius: 10px;
			}
		}
	}
}

.approval-status {
	width: 272px;
	height: 30px;
	border-radius: 15px;
	display: flex;
	color: #fff;
	border-bottom-right-radius: 0px;
	.img-container {
		width: 35px;
		height: 30px;
		position: relative;
		img {
			position: absolute;
			top: 5px;
			left: 7.5px;
			width: 20px;
			height: 20px;
			&.check {
				top: 9px;
				left: 11.5px;
				width: 12px;
				height: 12px;
			}
		}
	}
	.txt-container {
		border-left: 1px solid rgba(255, 255, 255, 0.6);
		padding: 6px 1rem;
	}
	&.approved {
		background-color: #3dcc55;
	}
	&.rejected {
		background-color: #ff0060;
	}
	&.pending {
		background-color: #ffbf00;
	}
}
