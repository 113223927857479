.upload-nav-bar {
	display: flex;
	width: 100%;
	height: 60px;
	background: #f2f4f7;
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	.upload-item {
		padding: 20px;
		margin: 0 20px;
		&.active {
			color: #5189e7;
			border-bottom: 1px solid #5189e7;
		}
	}
}

.upload-form-container {
	width: 100%;
	outline: none;
	padding: 60px 80px 60px 40px;
	.form-text {
		display: flex;
		justify-content: space-between;
		h3 {
			letter-spacing: -1px;
		}
		p {
			font-size: 14px;
			margin-bottom: 1.5rem;
		}
	}
	.dropzone-container {
		width: 100%;
		border-radius: 30px;
		border-bottom-right-radius: 0px;
		background-color: #f2f4f7;
		padding: 20px;
		margin-bottom: 1.5rem;
		.form-group {
			margin-bottom: 0px;
		}
		.dropzone .dropzone-input {
			height: 300px;
			border: dashed 1px #5189e7;
			border-radius: 30px;
			border-bottom-right-radius: 0px;
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;
			p {
				font-size: 24px;
				font-weight: normal;
				font-stretch: normal;
				font-style: normal;
				line-height: 1.5;
				letter-spacing: -1px;
				color: #5189e7;
				padding: 0 7.5%;
				text-align: center;
			}
			&.has-files {
				border: none;
				background-color: #e2e9f5;
				position: relative;
				.clear-files-btn {
					color: #000;
					opacity: 0.4;
					background: transparent;
					border: 2px solid rgba(0, 0, 0, 0.4);
					border-radius: 50%;
					position: absolute;
					top: 20px;
					right: 20px;
					font-weight: 700;
					font-size: 14px;
					height: 18px;
					width: 18px;
					padding-top: 0px;
					padding-bottom: 15px;
					padding-left: 1px;
					padding-right: 1px;
					line-height: 14px;
					&:hover {
						opacity: 0.5;
						border: 2px solid rgba(0, 0, 0, 0.5);
					}
				}
			}
		}
	}
	.upload-validation {
		display: flex;
		margin-left: 20px;
		.form-check {
			margin-right: 1.5rem;
			height: 25px;
			display: flex;
			align-items: center;
			input {
				width: 25px;
				height: 25px;
				margin-top: 0px;
			}
			label {
				width: 365px;
				margin-left: 0.75rem;
				a {
					text-decoration: underline;
				}
			}
			.invalid-feedback {
				display: none;
			}
		}
	}
	.submit-btn {
		width: 100%;
		height: 80px;
		border-radius: 30px;
		border-top-right-radius: 0px;
		background-color: #5189e7;
		text-align: left;
		padding: 20px 60px 20px 20px;
		margin: 20px 0;
		font-size: 24px;
		line-height: 1.25;
		letter-spacing: -1px;
		display: flex;
		align-items: center;
		position: relative;
		&.disabled {
			opacity: 0.3;
		}
		&.page-two {
			margin: 0;
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
		}
		span.loading {
			margin-left: 16px;
		}
		svg {
			padding-bottom: 3px;
		}
		.arrow-container {
			position: absolute;
			top: 0;
			right: 0;
			width: 80px;
			height: 80px;
			border-left: 1px solid rgba(255, 255, 255, 0.2);
			img {
				position: absolute;
				top: calc(50% - 5px);
				left: calc(50% - 9px);
			}
		}
	}
	.page-two-header {
		margin-bottom: 1.5rem;
	}
	.mobile-text {
		display: none;
	}
	.file-name-finish {
		width: 50%;
	}
	.phone-container {
		display: flex;
	}
	.react-tel-input .form-control {
		border: none;
		width: 6rem;
		flex: 0 1 auto;
		font-size: 16px;
		height: calc(1.5em + 0.75rem + 2px);
	}
	.progress {
		width: 50%;
		margin-left: auto;
		margin-top: 1rem;
	}
	.page-three-container {
		text-align: center;
		max-width: 500px;
		margin: auto;
		h6 {
			margin: 10px 0 20px;
		}
		.account-link {
			text-decoration: underline;
			cursor: pointer;
			&:hover {
				opacity: 0.8;
			}
		}
	}
	.form-control {
		font-size: 16px;
	}
}

.PhoneInputInput {
	display: block;
	width: 100%;
	height: calc(1.5em + 0.75rem + 2px);
	padding: 0.375rem 0.75rem;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	background-clip: padding-box;
	border-radius: 0.25rem;
	transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	font-size: 16px;
	margin-left: 0.5rem;
}
.PhoneInputCountryIcon {
	margin: 0 0.25rem;
}
@media screen and (max-width: 768px) {
	.upload-form-container {
		padding: 30px 20px;
		.form-text {
			flex-direction: column;
			p {
				font-size: 14px;
				margin-bottom: 0.5rem;
			}
			div > p {
				margin-bottom: 1.5rem;
			}
		}
		.upload-validation {
			flex-direction: column;
		}
		.mobile-text {
			display: block;
		}
		.desktop-text {
			display: none;
		}
		.form-page-two {
			.form-row {
				flex-direction: column;
				.col {
					width: 100%;
					&.first {
						border-top-right-radius: 30px;
					}
					&.second {
						border-radius: 0px;
					}
					&.second-last {
						border-radius: 0px;
					}
					&.last {
						border-bottom-left-radius: 30px;
					}
				}
			}
		}

		.file-name-finish {
			width: 100%;
		}
	}
}

@media screen and (max-width: 767px) {
	.upload-nav-bar {
		justify-content: center;
		.upload-item {
			display: flex;
			align-items: center;
			padding: 10px;
			margin: 0 7.5px;
		}
	}
	.upload-form-container {
		.submit-btn {
			font-size: 20px;
			.arrow-container {
				width: 60px;
			}
		}
	}
}
