@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;800&display=swap");

#partnerBackgroundVideo {
	position: fixed;
	right: 0;
	bottom: 0;
	min-width: 100%;
	min-height: 100%;
	z-index: -1;
}
.partner-page {
	padding: 1rem 0;
	width: 90%;
	max-width: 1400px;
	margin: auto;
	font-family: "Poppins", sans-serif;
	.logo-header {
		display: flex;
		justify-content: center;
	}
	.desc-block {
		display: flex;
		align-items: center;
		margin-bottom: 1.5rem;
		margin-top: 1rem;
		&.no-video {
			justify-content: center;
			.desc-cta {
				display: flex;
				flex-direction: column;
				align-items: center;
				h2,
				p {
					text-align: center;
				}
			}
		}
		> div {
			width: 50%;
			padding: 1rem;
		}
		&.reverse {
			flex-direction: row-reverse;
		}
		.desc-cta {
			padding-right: 2rem;
			h2 {
				text-transform: uppercase;
				font-weight: 800;
				font-size: 55px;
				line-height: 1;
				margin-bottom: 1.5rem;
				.blue {
					color: #0089d1;
				}
			}
			p.extra-margin {
				margin-bottom: 1.5rem;
			}
			button {
				background-color: #0089d1;
				text-transform: uppercase;
				font-weight: 800;
				padding: 20px 50px;
			}
		}

		.image-cont {
			video {
				width: 100%;
			}
		}
	}
}
@media screen and (max-width: 1000px) {
	.pets-on-q {
		.desc-block {
			flex-direction: column;

			> div {
				display: flex;
				flex-direction: column;
				align-items: center;
				width: 100%;
				p {
					width: 100%;
					max-width: 500px;
				}
				h2 {
					max-width: 500px;
				}
			}
			&.reverse {
				display: flex;
				flex-direction: column-reverse;
			}
			.image-cont {
				img {
					width: 100%;
				}
				video {
					width: 100%;
				}
			}
		}
	}
}
