@font-face {
	font-family: "Platelet";
	src: url("../fonts/Platelet_Regular.woff") format("woff");
}
@font-face {
	font-family: "Aura";
	src: url("../fonts/Aura-Regular.woff") format("woff");
}
@import url("https://fonts.googleapis.com/css?family=PT+Sans&display=swap");

.main-page-container {
	color: #fff;
	display: flex;
	.bg-image {
		width: 100vw;
		height: 100vh;
		position: fixed;
		top: 0px;
		left: 0px;
		background-size: cover;
		z-index: -1;
		background-size: cover;
		background-image: url("../images/homepage-main.jpg");
	}
	.half {
		width: 50%;
		height: 100vh;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		&.left {
			padding-left: 80px;
			.main-page-title {
				width: 100%;
				max-width: 600px;
				position: relative;
				.header-img {
					width: 95%;
					margin-top: 1rem;
				}
				h1 {
					padding-top: 60px;
					font-family: Aura, sans-serif;
					font-size: 200px;
					line-height: 0.5;
					letter-spacing: -9px;
					color: #ffffff;
					margin: 0;
					text-transform: uppercase;
					span {
						position: relative;
						left: 120px;
					}
				}
				.header {
					display: flex;
					align-items: center;
					h1 {
						width: 250px;
					}
				}
				.up-right-arrow {
					position: absolute;
					right: 40px;
					top: 420px;
				}
				.x-icon {
					position: absolute;
					left: 10px;
					top: 215px;
				}
				.sign-up-starburst {
					position: absolute;
					top: 225px;
					left: 375px;
					.text {
						position: absolute;
						top: 42.5%;
						left: calc(50% - 55px);
						font-size: 22px;
						font-family: Platelet, sans-serif;
						text-transform: uppercase;
						color: #131415;
					}
					.arrow {
						position: absolute;
						top: 30%;
						left: calc(50% - 10px);
					}
				}
			}
		}
		&.right {
			padding-right: 60px;
			.page-desc {
				padding: 20px;
				h2 {
					width: 460px;
					font-family: Platelet, sans-serif;
					font-size: 72px;
					line-height: 0.61;
					letter-spacing: -7px;
					text-transform: uppercase;
				}
				p {
					width: 460px;
					font-family: PT Sans, sans-serif;
					font-size: 14px;
					line-height: 1.71;
					letter-spacing: normal;
				}
			}

			.signup-button {
				width: 271px;
				height: 100px;
				background-color: #ffbf00;
				color: #000;
			}
		}
		.bottom-part {
			display: flex;
			justify-content: space-between;
			padding: 20px;
			font-size: 14px;
			font-family: Platelet, sans-serif;
			text-transform: uppercase;
			align-items: center;
			.links {
				display: flex;
				flex-direction: column;
				justify-content: flex-end;
			}
			.learn-more {
				visibility: hidden;
				font-size: 24px;
				color: #ff0060;
			}
		}
	}
}
.flex {
	display: flex;
}
.relative-btn {
	position: relative;
	padding: 0;
	border: none;
	.text {
		font-family: Platelet, sans-serif;
		font-size: 24px;
		line-height: 24px;
		text-transform: uppercase;
		position: absolute;
		top: 18px;
		left: 19px;
		white-space: nowrap;
	}
	.arrow {
		position: absolute;
		height: 11px;
		bottom: 20px;
		right: 20px;
	}
}

@media screen and (max-width: 1320px) {
	.main-page-container {
		.half.right {
			.page-desc {
				text-align: right;
				h2,
				p {
					margin-left: auto;
				}
			}
			.signup-button {
				margin-left: auto;
				margin-right: 20px;
			}
		}
	}
}

@media screen and (max-width: 1150px) {
	.main-page-container {
		.half.left {
			.main-page-title {
				h1 {
					font-size: 150px;
					letter-spacing: -6px;
					span {
						left: 90px;
					}
				}
				.x-icon {
					top: 175px;
					width: 70px;
					height: 70px;
					img {
						width: 100%;
						height: 100%;
					}
				}
				.sign-up-starburst {
					width: 120px;
					height: 120px;
					left: 275px;
					top: 190px;
					.starburst {
						width: 100%;
						height: 100%;
					}
					.text {
						font-size: 16px;
						left: calc(50% - 41px);
					}
				}
				.up-right-arrow {
					width: 70px;
					height: 70px;
					top: 330px;
					right: 180px;
					img {
						width: 100%;
						height: 100%;
					}
				}
			}
		}
		.half.right {
			.page-desc {
				text-align: right;
				h2,
				p {
					margin-left: auto;
				}
			}
			.signup-button {
				margin-left: auto;
				margin-right: 20px;
			}
		}
	}
}
@media screen and (max-width: 990px) {
	.main-page-container {
		flex-direction: column;
		.left.half {
			.bottom-part {
				display: none;
			}
		}
		.half {
			width: 100%;
			height: auto;
		}
	}
}
@media screen and (max-width: 767px) {
	.main-page-container {
		.half.left {
			padding-left: 5%;
			.main-page-title {
				padding-top: 75px;
				width: 100%;
				max-width: 600px;
				h1 {
					font-size: 120px;
					letter-spacing: -4px;
					span {
						left: 60px;
					}
				}
				.x-icon {
					top: 230px;
					width: 50px;
					height: 50px;
					img {
						width: 100%;
						height: 100%;
					}
				}
				.sign-up-starburst {
					width: 120px;
					height: 120px;
					left: 210px;
					top: 225px;
					.starburst {
						width: 100%;
						height: 100%;
					}
					.text {
						font-size: 14px;
						left: calc(50% - 35px);
					}
				}
				.up-right-arrow {
					width: 60px;
					height: 60px;
					top: 350px;
					right: 20px;
					img {
						width: 100%;
						height: 100%;
					}
				}
			}
		}
		.half.right {
			padding: 0px;
			.page-desc {
				text-align: right;
				h2,
				p {
					width: 100%;
					margin: auto;
					br {
						display: none;
					}
				}
				h2 {
					font-size: 48px;
					margin-bottom: 1.5rem;
				}
			}
			.signup-button {
			}
		}
	}
}
