@import url("https://fonts.googleapis.com/css?family=Work+Sans&display=swap");

.new-user-page-container {
	background-color: #fff;
	font-family: Work Sans;
}

.new-user-page-section {
	min-height: calc(100vh - 5rem);
}
@media screen and (max-width: 991px) {
	.new-user-page-section {
		min-height: calc(100vh - 20rem);
	}
}
.new-user-page-section .new-user-page-register-btn {
	align-self: center;
	margin-bottom: 3rem;
}

.new-user-page-section {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.new-user-page-section h1 {
	margin: 3rem;
}

.new-user-page-section p {
	font-size: 1.75rem;
	text-align: center;
}

.new-user-page-section-text {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 4rem;
}
.submit-btn {
	width: 350px;
	height: 80px;
	border-radius: 30px;
	border-top-right-radius: 0px;
	background-color: #5189e7;
	text-align: left;
	padding: 20px 35px 20px 20px;
	margin: 20px 0;
	font-size: 24px;
	line-height: 1.25;
	letter-spacing: -1px;
	display: flex;
	align-items: center;
	position: relative;
	&.disabled {
		opacity: 0.3;
	}
	span.loading {
		margin-left: 16px;
	}
	svg {
		padding-bottom: 3px;
	}
	.arrow-container {
		position: absolute;
		top: 0;
		right: 0;
		width: 80px;
		height: 80px;
		border-left: 1px solid rgba(255, 255, 255, 0.2);
		img {
			position: absolute;
			top: calc(50% - 5px);
			left: calc(50% - 9px);
		}
	}
}
