.dashboard-page {
	padding: 40px 80px 0 40px;
	max-height: 100vh;
	overflow: scroll;
	.dashboard-header {
		margin-top: 10px;
	}
	.dashboard-tooltip {
		width: 100%;
		padding: 8px 60px;
		margin-bottom: 15px;
		font-size: 16px;
		border-radius: 15px;
		display: flex;
		color: #fff;
		border-bottom-right-radius: 0px;
		background-color: #ffbf00;
		position: relative;
		cursor: pointer;
		.img-container {
			height: 100%;
			position: absolute;
			right: 0;
			top: 0;
			border-left: 1px solid #fff;
			padding: 10px 20px;
			display: flex;
			align-items: center;
			img {
				width: 20px;
				height: 20px;
			}
		}
		.txt-container {
			border-left: 1px solid rgba(255, 255, 255, 0.6);
			padding: 6px 1rem;
		}
	}
	.videos-cont {
		background-color: #f2f4f7;
		padding: 20px;
		border-radius: 30px;
		margin-bottom: 30px;
		.header {
			text-transform: uppercase;
			color: #000;
			opacity: 0.4;
		}
	}
	.video-cont {
		position: relative;
		padding-bottom: 33%;
		height: 0;
		width: 60%;
		iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}
	.description {
		width: 40%;
		padding-left: 40px;
		p {
			font-size: 16px;
		}
		button {
			background-color: #5189e7;
			border-radius: 30px;
			padding: 10px 30px;
			margin: auto;
		}
	}
	.help-text {
		max-width: 600px;
		margin: 30px auto;
		font-size: 16px;
	}
	.center {
		text-align: center;
	}
	.youtube-text {
		color: red;
	}
	.abc-image {
		max-width: 600px;
		width: 50%;
		margin: 0 auto 30px;
	}
	@media screen and (max-width: 1200px) {
		.embed-cont {
			flex-direction: column;
		}
		.video-cont {
			width: 100%;
			margin-bottom: 30px;
			padding-bottom: 56.25%;
		}
		.description {
			width: 100%;
			padding-left: 0;
			display: flex;
			flex-direction: column;
			button {
				margin: auto;
			}
		}
	}
}
@media screen and (max-width: 768px) {
	.dashboard-page {
		padding: 10% 40px 10% 40px;
	}
}
