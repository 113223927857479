@import url("https://fonts.googleapis.com/css?family=Work+Sans&display=swap");

body {
	font-family: "Work Sans";
}

.policy-menu {
	position: fixed;
	z-index: 999;
	height: 75px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	width: 100%;
	background: #fff;
	.back {
		border-right: 1px solid rgba(0, 0, 0, 0.2);
		opacity: 0.5;
		padding: 0 20px;
		display: flex;
		align-items: center;
		cursor: pointer;
		height: 100%;
		img {
			transform: rotate(180deg);
			margin-right: 5px;
			vertical-align: middle;
			padding-top: 2px;
		}
	}
	.logo-container {
		width: 40px;
		height: 60px;
		img {
			width: 100%;
			height: 100%;
		}
	}
}

.policy-container {
	padding-top: 85px;
	width: 95%;
	max-width: 1200px;
	margin: auto;
	h1,
	h2 {
		text-align: center;
		margin-top: 1rem;
		margin-bottom: 2rem;
	}
	h2 {
		margin-top: 1.5rem;
		margin-bottom: 1.5rem;
	}
	&.contrib-agreement {
		ol li {
			margin-bottom: 1rem;
		}
		p {
			margin: 0 0 0.25rem;
			&.ml-8 {
				margin-left: 3rem;
			}
		}
	}
}
