.partner-container {
	padding: 40px 80px 0 40px;
	h3 {
		color: #5189e7;
		font-size: 42px;
		margin-bottom: 1rem;
	}
	h6 {
		font-size: 24px;
		margin-bottom: 2rem;
	}
	.submit-area {
		margin-top: 4rem;
		p {
			font-weight: 700;
			font-size: 16px;
			padding-left: 1rem;
		}
	}
	&.upload-form-container .upload-validation .form-check {
		height: auto;
	}
	@media screen and (max-width: 767px) {
		.submit-area {
			margin-top: 1rem;
			p {
				padding-left: 0;
			}
		}

		.label-area {
			p {
				padding-left: 0;
			}
		}
		.channel-btns {
			flex-direction: column;
			.submit-btn {
				width: 100%;
				margin: 10px 0;
			}
			.back-btn {
				margin: 10px 0;
			}
		}
		padding: 20px 5% 40px;
	}
}
.yt-submit {
	background-color: red !important;
	border-color: red !important;
}

.channel-select {
	.channel {
		margin-left: 1rem;
		border: 1px solid #ddd;
		display: inline-block;
		img {
			width: 60px;
			height: 60px;
		}
		span {
			margin: 0 1rem;
			font-size: 16px;
			font-weight: bold;
		}
	}
}

.label-area {
	p {
		font-weight: 400;
		font-size: 16px;
		padding-left: 1rem;
	}
}

.channel-btns {
	display: flex;
	margin-top: 10px;
	.submit-btn {
		width: 400px;
		margin: 10px 0;
	}
	.back-btn {
		margin: 10px;
		background-color: #aaa;
		border-color: #aaa;
	}
}
