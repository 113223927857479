.partner-video {
	position: fixed;
	z-index: -99;
	width: 100%;
	height: 100%;
	background-color: #000;
	video,
	img {
		width: 100%;
		height: 100%;
	}
}
@media screen and (max-width: 767px) {
	.partner-video {
		video,
		img {
			height: 100%;
			width: auto;
			margin-left: calc(-10.5 / 16 * 100vh);
		}
	}
}
.partner-details {
	.logo-cont {
		margin: auto;
		width: 200px;
		position: absolute;
		left: calc(50% - 100px);
		top: 20%;
		-webkit-box-shadow: 0px 0px 30px 11px rgba(0, 0, 0, 0.25);
		-moz-box-shadow: 0px 0px 30px 11px rgba(0, 0, 0, 0.25);
		box-shadow: 0px 0px 30px 11px rgba(0, 0, 0, 0.25);
		border-radius: 50%;
		img {
			width: 100%;
			border-radius: 50%;
		}
	}
	.signup-btn {
		margin: auto;
		width: 330px;
		position: absolute;
		left: calc(50% - 165px);
		top: calc(40% + 200px);
		-webkit-box-shadow: 0px 0px 30px 11px rgba(0, 0, 0, 0.25);
		-moz-box-shadow: 0px 0px 30px 11px rgba(0, 0, 0, 0.25);
		box-shadow: 0px 0px 30px 11px rgba(0, 0, 0, 0.25);
		button {
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 100%;
			background-color: rgba(0, 0, 0, 0.6);
			color: #fff;
			text-transform: uppercase;
			font-weight: 700;
			padding: 15px 20px;
			font-size: 24px;
			border-color: #000;
			outline: none !important;
			svg {
				width: 30px;
				height: 30px;
				stroke: #fff;
				fill: #fff;
			}
		}
	}
}
