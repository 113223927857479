.partner-register-container {
	background: #eee;
	padding: 1rem 0 5rem;
	min-height: 100vh;
	position: relative;
	.card {
		width: 95%;
		max-width: 600px;
		margin: auto;
		padding: 2rem;
	}
	.check input:checked ~ .checkmark {
		background-color: #fff;
	}
	.check input ~ .checkmark {
		background-color: #fff;
		border-color: #000;
	}
	.check:hover input ~ .checkmark {
		background-color: #fff;
	}
	.oauth-btn {
		color: #fff;
		text-transform: uppercase;
		padding: 1rem;
		width: 100%;
		max-width: 400px;
		&.fb-login {
			background-color: #3b5998;
			border-color: #3b5998;
			margin-bottom: 0.75rem;
		}
		&.google-login {
			background-color: #c4302b;
			border-color: #c4302b;
		}
	}
	.navbar {
		position: absolute;
		bottom: 0;
		width: 100%;
		.navbar-nav:last-child {
			margin-left: auto;
			margin-right: 5rem;
		}
	}
	.form-container-centered {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	h1,
	h2 {
		text-align: center;
		margin-bottom: 2rem;
	}
	h2 {
		margin-top: 2rem;
	}
	.errors {
		color: #dc3545;
		font-size: 11px;
		margin-top: 0.25rem;
		margin-left: 0.25rem;
	}
	.logo-cont {
		margin: auto;
		position: absolute;
		top: 22px;
		img {
			width: 100%;
		}
		&.brand-logo-cont {
		}
		&.hoodville {
			width: 52px;
			height: 52px;
			right: 30px;
			top: 25px;
			img {
				border-radius: 50%;
			}
		}
		&.pets-on-q {
			width: 80px;
			height: auto;
			right: 30px;
			top: 0px;
		}
	}
}
@media screen and (max-width: 767px) {
	.partner-register-container {
		.navbar {
			position: static;
			margin-top: 1rem;
		}
	}
}
